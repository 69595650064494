import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,

  state: {
    errors: [],
    requestDeliveries: [],
    requestCancelled:[],
    myRequests: [],
    events:[],
    estimateFare: null,
    setRequestUpdate:null,
    isLoading: false,
    isSuccess: false
  },

  getters: {
    requestDeliveries: state => state.requestDeliveries,
    requestCancelled: state => state.requestCancelled,
    setRequestUpdate: state => state. setRequestUpdate,
    myRequests: state => state.myRequests,
    estimateFare: state => state.estimateFare,
    events:state=>state.events,
    errors: state => state.errors,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    
    setRequestUpdate(state, setRequestUpdate) {
      state.setRequestUpdate = setRequestUpdate;
    },
    setEstimateFare(state, estimateFare) {
      state.estimateFare = estimateFare;
    },
    setRequestDeliveries(state, requestDeliveries) {
      state.requestDeliveries = requestDeliveries;
    },
    setRequestCancelled(state, requestCancelled) {
      state.requestCancelled = requestCancelled;
    },
    setMyRequests(state, myRequests) {
      state.myRequests = myRequests;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    },
    setEvents(state, events) {
      state.events = events;
    }

  },

  actions: {
    
    requestEstimate({
      commit
    },params) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      
      axios
        .get("/api/user/estimated/fare?"+params)
        .then( async response => {

          if (response && response.data) {
            

            commit("setIsLoading", false, {
              root: true
            });
            commit("setIsSuccess", true, {
              root: true
            });

           
            await commit("setEstimateFare", response.data);
          }
        })
        .catch(error => {
          commit("setErrors", error, {
            root: true
          });

          commit("setIsLoading", false, {
            root: true
          });
        });
    },
    getRequestData({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      // commit("setIsLoading", true, {
      //   root: true
      // });
      // commit("setIsSuccess", false, {
      //   root: true
      // });

      let endpoint="";
      if(window.localStorage.getItem("role")=='Driver'){
        endpoint= "/api/provider/requests/history";
      }else{
        endpoint= "/api/user/trips1";
      }

      axios
        .get(endpoint,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
       
          if (response && response.data) {
           
          let requestDeliveries= response.data;
          commit("setRequestDeliveries", requestDeliveries);

          let cancelled=response.data.filter(req=>req.status==='CANCELLED');

          commit("setRequestCancelled", cancelled);
          
          }
        })
        .catch(error => {
          commit("setErrors", error, {
            root: true
          });

          commit("setIsLoading", false, {
            root: true
          });
        });
    },

    async checkRequestUpdate({
      commit
    },requestId) {
      commit("setErrors", {}, {
        root: true
      });

      let endpoint="";
        endpoint= "/api/user/trip/"+requestId;
   
     return await axios
        .get(endpoint,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       });
    },
    
    getRequestEvents({
      commit
    },requestId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true);

      axios
        // .get("/api/user/request/getEvents/"+requestId,{
          .get("/api/user/request/getEvents/"+requestId,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
          if (response && response.data) {
  
            commit("setEvents", response.data);

            commit("setIsLoading", false);

          }
        });
    },

    getCurrentRequestData({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
 
      let endpoint="";
      if(window.localStorage.getItem("role")=='Driver'){
        endpoint= "/api/provider/trip";
      }else{
        endpoint= "/api/user/request/check";
      }

      axios
        .get(endpoint,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
          if (response && response.data) {
            let myRequests=[];
          
          if(window.localStorage.getItem("role")=='Driver'){
            response.data.requests.forEach(element => {
              myRequests.push(element.request);
              });
          }else{
             myRequests= response.data.data;
          }

          commit("setMyRequests", myRequests);
          
          
          }
        })
        .catch(error => {
          commit("setErrors", error, {
            root: true
          });

      
        });
    },

   
    sendNewRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post('/api/user/send/request', data,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
          console.log(response);
          if (response && response.data) {

            commit("setIsLoading", false, {
              root: true
            });   
            commit("setIsSuccess", true, {
              root: true
            });
           
          }


        }).catch(err=>{
          console.log(err);
        });;
    },

    sendRequestRating({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
     
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post('/api/user/rate/provider', data,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
         
          if (response && response.data) {
            
            commit("setIsSuccess", true, {
              root: true
            });
           
          }


        }).catch(err=>{
          console.log(err);
        });;
    },
  
  
  }
};