import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,

  state: {
    errors: [],
    serviceTypes: [],
    packageTypes: [],
    weightTypes:[],
    goodsTypes:[],
    isLoading: false,
    isSuccess: false
  },

  getters: {
    serviceTypes: state => state.serviceTypes,
    packageTypes: state => state.packageTypes,
    weightTypes: state => state.weightTypes,
    goodsTypes: state => state.goodsTypes,
    errors: state => state.errors,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    setServiceType(state, serviceTypes) {
      state.serviceTypes = serviceTypes;
    },
    setPackageType(state, packageTypes) {
      state.packageTypes = packageTypes;
    },

    setWeightType(state, weightTypes) {
      state.weightTypes = weightTypes;
    },
    setGoodsType(state, goodsTypes) {
      state.goodsTypes = goodsTypes;
    },
    
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    }
  },

  actions: {
    getServiceType({
      commit
    }) {
        axios
        .get("/api/user/services",{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
          if (response && response.data) {

            let res = response.data;
            commit("setServiceType", res);

            commit("setIsLoading", false, {
              root: true
            });
          
            commit("setIsSuccess", true, {
              root: true
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    getOtherType({
      commit
    }) {
        axios
        .get("/api/user/request/data",{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
          if (response && response.data) {

            let res = response.data;
            commit("setIsLoading", false, {
              root: true
            });
            let package_types=[];
            let weight_types=[];
            let goods_types=[];

            if(res.package_types.length > 0){
              res.package_types.forEach(el=>{
                package_types.push({ label: el.name, value: el.id })
              });
            }
            if(res.weight_types.length > 0){
              res.weight_types.forEach(el=>{
                weight_types.push({ label: el.name, value: el.id })
              });
            }
      
             if(res.goods_types.length > 0){
              res.goods_types.forEach(el=>{
                goods_types.push({ label: el.name, value: el.id })
              });
             }
            commit("setPackageType", package_types);
            commit("setWeightType", weight_types);
            commit("setGoodsType", goods_types);
          
            commit("setIsSuccess", true, {
              root: true
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

  
  }
};