import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// const guest = (to, from, next) => {
//   if (!localStorage.getItem("authToken")) {
//     return next();
//   } else {
//     return next("/");
//   }
// };
const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
      return next();
  
  } else {
    return next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "Welcome",
    // beforeEnter: guest
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Pages/Welcome.vue")
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    // beforeEnter: guest
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Pages/Privacy.vue")
  },
  {
    path: "/terms-conditions",
    name: "Terms",
    // beforeEnter: guest
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Pages/Terms.vue")
  },
  {
    path: "/legal",
    name: "Legal",
    // beforeEnter: guest
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Pages/Legal.vue")
  },
  
  {
    path: "/login/:userType",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/Login.vue")
  },
  {
    path: "/authenticate/:userType",
    name: "Authenticate",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/Authenticate.vue")
  },
  
  {
    path: "/register/:userType",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Auth/Register.vue")
  },
  {
    path: "/home/:userType",
    name: "Home",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/Home")
  },
  {
    path: "/request/:userType",
    name: "Request",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/Request")
  },
  {
    path: "/trackRequest/:userType/:requestId",
    name: "TrackRequest",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/TrackRequest")
  },
  
  {
    path: "/newRequest",
    name: "NewRequest",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/NewRequest")
  },
  {
    path: "/notifications",
    name: "Notification",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/Notification")
  },

  {
    path: "/help",
    name: "Help",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/Help")
  },
  {
    path: "/promotions",
    name: "Promotion",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/Promotion")
  },
  
  
   {
    path: "/myProfile",
    name: "Profile",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/Profile")
  },

  { path: '*', redirect: '/' }
  

];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});



export default router;
