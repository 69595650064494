import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,

  state: {
    errors: [],
    wallet: null,
    walletMessage:null,
    walletAmount:null,
    isLoading: false,
    isSuccess: false
  },

  getters: {
    wallet: state => state.wallet,
    walletMessage: state => state.walletMessage,
    walletAmount: state => state.walletAmount,
    errors: state => state.errors,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    setWallet(state, wallet) {
      state.wallet = wallet;
    },
    setWalletMessage(state, walletMessage) {
      state.walletMessage = walletMessage;
    },

    setWalletAmount(state, walletAmount) {
      state.walletAmount = walletAmount;
    },
    
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    }
  },

  actions: {
  
    addWallet({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
    
      commit("setIsSuccess", false, {
        root: true
      });

      let endpoint="";
      if(window.localStorage.getItem("role")=='Driver'){
        endpoint= "/api/provider/profile/add/money";
      }else{
        endpoint= "/api/user/add/money";
      }

      return axios
        .post(endpoint, data,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
          if (response && response.data) {
            commit("setWalletAmount", response.data.user.wallet_balance);
//setWallet
            commit("setWalletMessage", response.data.message);
            
            commit("setIsSuccess", true, {
              root: true
            });
           
          }


        }).catch(err=>{
          console.log(err);
        });;
    },
  
  
  }
};