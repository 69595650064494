<template>
  <transition name="fade">
    <header
      id="header"
      class="main-header header-sticky header-sticky-smart header-style-10"
      style="background-color: #cc0000 !important; overflow:hidden;"
    >
      <div class="header-wrapper sticky-area border-bottom">
        <div class="container">
          <div class="row">
            <div class="text-white col-lg-12">
              <p class="mb-1 text-white text-center">
                ⚡️ There's No Internet Connection
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  </transition>
</template>

<script>
export default {
  name: "NoInternet",
};
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
