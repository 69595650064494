<template>
 
 <transition name="slide-fade">
    <div
      v-if="showAlert"
      class="alert alert-danger p-2 alert-dismissible fade show"
      role="alert"
    >
      <div class="message p-3">{{ message }}</div>
      
    </div>
  </transition>

</template>


<script>
    export default {
        name: 'MessageErrorFadeOut',
        props: ["message", "show","timeout"],
          data: function () {
        return {
            showAlert: this.show
        }
    },
       mounted() {
            setTimeout( ()=> {
                            this.showAlert = false;
                        }, this.timeout?this.timeout:10000); // hide the message after 3 seconds
            }
    }
</script>
<style scoped>
    /* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>