import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import NProgress from "nprogress";
import "../node_modules/nprogress/nprogress.css";
import Notifications from "vue-notification";
import { VueSpinners } from "@saeris/vue-spinners";
import VueCompositionApi from "@vue/composition-api";
import "@morioh/v-lightbox/dist/lightbox.css";
import Lightbox from "@morioh/v-lightbox";
import icons from "v-svg-icons";
import VTooltip from "v-tooltip";

import * as VueGoogleMaps from "vue2-google-maps";
import "./vee-validate";
import moment from "moment";
import vueScrollto from "vue-scrollto";
import { extend, setInteractionMode, localize } from "vee-validate";
import { dimensions } from "vee-validate/dist/rules";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import en from "vee-validate/dist/locale/en.json";

import VueMask from "v-mask";
Vue.use(VueMask);
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
import GoogleAuth from "./config/google_oAuth.js";

const gauthOption = {
  clientId:
    "809002737792-k90ldlaovsil8t1nh7tmg4mh4nmh03ni.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(GoogleAuth, gauthOption);

window.Event = new Vue();

window.Pusher = require("pusher-js");
import {
  MazAvatar,
  MazBottomSheet,
  MazBtn,
  MazBtnGroup,
  MazCard,
  MazCarousel,
  MazCheckbox,
  MazCollapse,
  MazDialog,
  MazDraggableList,
  MazDropdown,
  MazFlex,
  MazGallery,
  MazImg,
  MazInput,
  MazInputTags,
  MazList,
  MazListItem,
  MazLoader,
  MazPagination,
  MazPicker,
  MazPlotly,
  MazProgressBar,
  MazPhoneNumberInput,
  MazRadio,
  MazReadMore,
  MazResponsiveMenu,
  MazSearch,
  MazSelect,
  MazSlider,
  MazSidebar,
  MazSpinner,
  MazStepper,
  MazSwitch,
  MazTabsBar,
  MazTabsContent,
  MazTabsContentItem,
  MazTransitionExpand,
} from "maz-ui";

Vue.use(MazAvatar);
Vue.use(MazBottomSheet);
Vue.use(MazBtn);
Vue.use(MazBtnGroup);
Vue.use(MazCard);
Vue.use(MazCarousel);
Vue.use(MazCheckbox);
Vue.use(MazCollapse);
Vue.use(MazDialog);
Vue.use(MazDraggableList);
Vue.use(MazDropdown);
Vue.use(MazFlex);
Vue.use(MazGallery);
Vue.use(MazImg);
Vue.use(MazInput);
Vue.use(MazInputTags);
Vue.use(MazList);
Vue.use(MazListItem);
Vue.use(MazLoader);
Vue.use(MazPagination);
Vue.use(MazPicker);
Vue.use(MazPlotly);
Vue.use(MazProgressBar);
Vue.use(MazPhoneNumberInput);
Vue.use(MazRadio);
Vue.use(MazReadMore);
Vue.use(MazResponsiveMenu);
Vue.use(MazSearch);
Vue.use(MazSelect);
Vue.use(MazSlider);
Vue.use(MazSidebar);
Vue.use(MazSpinner);
Vue.use(MazStepper);
Vue.use(MazSwitch);
Vue.use(MazTabsBar);
Vue.use(MazTabsContent);
Vue.use(MazTabsContentItem);
Vue.use(MazTransitionExpand);
Vue.use(vueScrollto);

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VueSpinners);
Vue.use(VueCompositionApi);
Vue.use(Lightbox);
Vue.use(VTooltip);
Vue.component("icon", icons);

setInteractionMode("lazy");

extend("dimensions", dimensions);

// We extend the ruleset of VeeValidate
// by calling the "extend" function.
extend("min", {
  // With the parameter "params" we can specify,
  // which values should be passed into the "validate" function.
  params: ["length"],
  // With the parameter "message" we can display an easy
  // understandable error message.
  message: "The {_field_} field must have at least {min} characters",
  validate(value, params) {
    // Here we check if the parameter "length"
    // is actually present.
    if (!params.length) {
      throw new Error('The "length" parameter is required, but not defined');
    }

    // At the end the content will be checked and validated
    return value && value.length >= params.length;
  },
});

localize({ en });

Vue.use(VueGoogleMaps, {
  load: {
    key: window.localStorage.getItem("gmIpositaOndemand"),
    libraries: "places",
  },
});



Vue.filter("formatDate2", function(value) {
  if (value) {
    return moment(String(value)).format("ddd, MMM Do YYYY, h:mm:ss a");
  }
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY h:mm:ss a");
  }
});

Vue.filter("formatTime", function(value) {
  if (value) {
    return moment(String(value)).format("h:mm:ss a");
  }
});

var filter = function(text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    Event.$emit("isTokenExpired", false);
    //  console.log(error);
    if (
      error &&
      error.response &&
      error.response.status &&
      (error.response.status === 403 || error.response.status === 401) &&
      (error.response.data.error == "token_invalid" ||
        error.response.data.error == "token_expired" ||
        error.response.data.error == "token_absent")
    ) {
      Event.$emit("isTokenExpired", true);
    }
    if (
      error &&
      error.response &&
      error.response.status &&
      (error.response.status === 500 ||
        error.response.status === 404 ||
        error.response.status === 400 ||
        error.response.status === 422)
    ) {
      if (error.response.data.error) {
        store.commit("setErrors", error.response.data);
        store.commit("setChangePasswordErrors", error.response.data.error);
      } else if (error.response.data.errors) {
        store.commit("setErrors", error.response.data.errors);
        store.commit("setChangePasswordErrors", error.response.data.errors);
      } else {
        store.commit("setErrors", error.response.data);
        store.commit("setChangePasswordErrors", error.response.data);
      }
    } else if (error.response && error.response.status === 401) {
      store.commit("setErrors", error.response.data);
      store.commit("setChangePasswordErrors", error.response.data);
      store.commit("auth/setUserData", null);
      localStorage.removeItem("authToken");
      localStorage.removeItem("isEmailVerified");
      localStorage.removeItem("role");
      router.push({
        name: "Welcome",
      });
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(function(config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };

  return config;
});

router.beforeResolve((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(async () => {
  NProgress.done();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

//serviceWorkerRegistration.register();
