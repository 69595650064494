import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,

  state: {
    errors: [],
    documentTypes: [],
    isLoading: false,
    isSuccess: false
  },

  getters: {
    documentTypes: state => state.documentTypes,
    errors: state => state.errors,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    setDocumentTypes(state, documentType) {
      state.documentTypes = documentType;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    }
  },

  actions: {
    getDocumentType({
      commit
    }) {
        axios
        .get("/api/provider/document/types",{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "X-Requested-With":"XMLHttpRequest"
          }
       })
        .then(response => {
        
          if (response && response.data) {

            let res = response.data;
            commit("setDocumentTypes", res.document);
          
            commit("setIsLoading", false, {
              root: true
            });
            
            commit("setIsSuccess", true, {
              root: true
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

  
  }
};