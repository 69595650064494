import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,

  state: {
    errors: [],
    notifications: [],
    isLoading: false,
    isSuccess: false
  },

  getters: {
    notifications: state => state.notifications,
    errors: state => state.errors,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
   
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    }
  },

  actions: {
    
    getNotifications({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
///api/provider/notification

      let endpoint=window.localStorage.getItem("role")=='Driver'? "/api/provider/notification":"/api/user/notification";
     
      return axios
        .get(endpoint)
        .then( async response => {

          if (response && response.data) {
            

            commit("setIsLoading", false, {
              root: true
            });
            commit("setIsSuccess", true, {
              root: true
            });

           
            await commit("setNotifications", response.data.notifications);
          }
        })
        .catch(error => {
          commit("setErrors", error, {
            root: true
          });

          commit("setIsLoading", false, {
            root: true
          });
        });
    },
  
  
  
  }
};