import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import serviceType from "./serviceType";
import documentType from "./documentType";
import requestTrip from "./requestTrip";
import wallet from "./wallet";
import notification from "./notification";
import promotion from "./promotion"


Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    errors: [],
    data: [],
    user: null,
    changePasswordErrors: [],
    isLoading:false,
    isSuccess:false,
    isResending:false,
    changePasswordSuccess:false
  },

  getters: {
    user: state => state.userData,
    changePasswordErrors: state => state.changePasswordErrors,
    errors: state => state.errors,
    data: state => state.data,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess,
    isResending: state => state.isResending,
    changePasswordSuccess: state => state.changePasswordSuccess,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setChangePasswordErrors(state, changePasswordErrors) {
      state.changePasswordErrors = changePasswordErrors;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setData(state, data) {
      state.data = data;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    },
    setIsResending(state, isResending) {
      state.isResending = isResending;
    },
    setChangePasswordSuccess(state, changePasswordSuccess) {
      state.changePasswordSuccess = changePasswordSuccess;
    }
  },

  actions: {
 
  },

  modules: {
    auth,
    serviceType,
    documentType,
    requestTrip,
    wallet,
    notification,
    promotion
  }
});
