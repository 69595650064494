import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,

  state: {
    errors: [],
    promotions: [],
    isLoading: false,
    isSuccess: false
  },

  getters: {
    promotions: state => state.promotions,
    errors: state => state.errors,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    setPromotions(state, promotions) {
      state.promotions = promotions;
    },
   
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    }
  },

  actions: {
    
    getPromotions({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      return axios
        .get("/api/user/new/promocodes")
        .then( async response => {

          if (response && response.data) {
            commit("setIsLoading", false, {
              root: true
            });
            commit("setIsSuccess", true, {
              root: true
            });
            await commit("setPromotions", response.data);
          }
        })
        .catch(error => {
          commit("setErrors", error, {
            root: true
          });

          commit("setIsLoading", false, {
            root: true
          });
        });
    },
  
  
  
  }
};