import axios from "axios";
import VUE_APP_API_URL from "./url";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
export default {
  namespaced: true,

  state: {
    errors: [],
    userData: null,
    isLoading: false,
    isSuccess: false,
    isResending: false,
    setting: null,
  },

  getters: {
    user: (state) => state.userData,
    setting: (state) => state.setting,
    errors: (state) => state.errors,
    isLoading: (state) => state.isLoading,
    isSuccess: (state) => state.isSuccess,
    isResending: (state) => state.isResending,
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    setUserData(state, user) {
      state.userData = user;
    },
    setSetting(state, setting) {
      state.setting = setting;
    },

    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    },
    setIsResending(state, isResending) {
      state.isResending = isResending;
    },
  },

  actions: {
    async getUserData({ commit }) {
   
      commit("setIsLoading", true, {
        root: true,
      });
     
      let endpoint =
        window.localStorage.getItem("role") == "Driver"
          ? "/api/provider/profile"
          : "/api/user/details";

      await axios
        .get(endpoint)
        .then( async (response) => {
         // console.log(response);
        
            let res = response.data;

            if (!res.picture) {
              res.picture = res.avatar;
            }

            if (res.isEmailVerified) {
              localStorage.setItem("isEmailVerified", true);
            }

            //console.log(res);
            commit("setUserData", res);
            commit("setIsLoading", false, {
              root: true,
            });

            await Event.$emit("userDataEvent", res);
         
        })
        .catch(() => {
          localStorage.removeItem("authToken");
          return (window.location.href =
            "/login/" + window.localStorage.getItem("role"));
        });
    },

    async getUserSetting({ commit }) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );

      await axios
        .get("/api/user/setting")
        .then((response) => {
          if (response && response.data.google_map_key && response.data.google_map_key.value) {
            window.localStorage.setItem("gmIpositaOndemand", response.data.google_map_key.value);
          }
          Event.$emit("settingEvent", response.data);
        })
        .catch(() => {});
    },

    getGridCode({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      window.Event.$emit("gridcodeLoaded", false);
      axios
        .get(
          data.grid_nav_url +
            "/api/gridNav/gridCode/allGridCode?user_id=" +
            data.user_id,
          {
            headers: {
              Authorization: `Bearer ${data.grid_nav_token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          window.Event.$emit("gridcodeLoaded", true);

          if (response && response.data && response.data.status == "success") {
            window.Event.$emit("userGridCodes", response.data.response);
          } else {
            window.Event.$emit("userGridCodes", []);
          }
        })
        .catch(() => {});
    },

    getFavoriteLocations({ commit }) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      axios
        .get("/api/user/favourite/location", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data && response.data.locations.length > 0) {
            window.Event.$emit(
              "userFavoriteLocations",
              response.data.locations
            );
          } else {
            window.Event.$emit("userFavoriteLocations", []);
          }
        })
        .catch(() => {});
    },
    //setSetting
    getSetting({ commit }) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );

      axios
        .get("/api/user/setting", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response) {
            commit("setSetting", response);
          }
        })
        .catch(() => {});
    },

    sendLoginRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      let endpoint =
        data.userType == "Driver"
          ? "/api/provider/oauth/token"
          : "/api/user/oauth/token";

      return axios
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data) {
            let res = response.data;
            localStorage.setItem("authToken", res.access_token);
            localStorage.removeItem("isEmailVerified");
            commit("setIsLoading", false, {
              root: true,
            });

            commit("setIsSuccess", true, {
              root: true,
            });
          }
        });
    },

    sendVerifyEmailRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      let endpoint =
        data.userType == "Driver" ? "/api/provider/verify" : "/api/user/verify";

      return axios
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data) {
            if (response.data.message != "Email Available") {
              Event.$emit("isEmailExist", true);
            } else {
              Event.$emit("isEmailExist", false);
            }
          }
        });
    },

    sendForgetPasswordOTP({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      let endpoint =
        data.userType == "Driver"
          ? "/api/provider/forgot/password"
          : "/api/user/forgot/password";

      return axios
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          //  console.log(response);
          if (response && response.data.success) {
            Event.$emit("getSentOTPUserMessage", response.data.message);
            if (response.data.user) {
              Event.$emit("getSentOTPUser", response.data.user);
            } else if (response.data.provider) {
              Event.$emit("getSentOTPUser", response.data.provider);
            } else {
              Event.$emit("getSentOTPUser", null);
            }
          } else {
            Event.$emit("getSentOTPUserError", response.data.message);
          }
        });
    },

    sendOTPRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });

      let endpoint =
        data.userType == "Driver"
          ? "/api/provider/send/otp"
          : "/api/user/send/otp";

      return axios
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data) {
            Event.$emit("otpSent", response.data);
          } else {
            Event.$emit("otpSent", null);
          }
        });
    },

    generateIoxternalGridbusinessKeyRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });

      let endpoint = "/api/user/generateIoxternalGridbusinessIntegrationKey";

      return axios
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data) {
            Event.$emit("key_generated", response.data);
          } else {
            Event.$emit("key_generated", null);
          }
        });
    },

    sendResetPasswordRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      let endpoint =
        data.userType == "Driver"
          ? "/api/provider/reset/password"
          : "/api/user/reset/password";

      return axios
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data) {
            Event.$emit("passwordChangedSuccess", response.data.message);
          } else {
            Event.$emit("passwordChangedSuccess", null);
          }
        });
    },

    sendLoginWithGoogleRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });

      return axios
        .post("/api/user/auth/google", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data) {
            let res = response.data;
            localStorage.setItem("authToken", res.access_token);
            localStorage.removeItem("isEmailVerified");
            commit("setIsLoading", false, {
              root: true,
            });

            commit("setIsSuccess", true, {
              root: true,
            });
          }
        });
    },
    //sendLoginWithFacebookRequest
    sendLoginWithFacebookRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });

      return axios
        .post("/api/user/auth/facebook", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data) {
            let res = response.data;
            localStorage.setItem("authToken", res.access_token);
            localStorage.removeItem("isEmailVerified");
            commit("setIsLoading", false, {
              root: true,
            });

            commit("setIsSuccess", true, {
              root: true,
            });
          }
        });
    },
    sendGricodeSignup({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );

      commit("setIsSuccess", false, {
        root: true,
      });

      return axios
        .post("/api/user/gridcode/register", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data && response.data.status == "success") {
            let res = response.data.response;

            window.Event.$emit("gridcodeUserIdEvent", res);

            commit("setIsSuccess", true, {
              root: true,
            });
          } else {
            window.Event.$emit("gridcodeSignupError", response.data.response);
          }
        });
    },
    sendGricodeSignin({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );

      commit("setIsSuccess", false, {
        root: true,
      });

      return axios
        .post("/api/user/gridcode/login", data, {
          // .post('/api/user/gridcode/login', data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data && response.data.status == "success") {
            let res = response.data.response;

            window.Event.$emit("gridcodeUserIdEvent", res);

            commit("setIsSuccess", true, {
              root: true,
            });
          } else {
            window.Event.$emit("gridcodeSignupError", response.data.response);
          }
        });
    },

    sendAddGridCode({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );

      commit("setIsSuccess", false, {
        root: true,
      });

      return axios
        .post(data.grid_nav_url + "/api/gridNav/gridCode/addGridCode", data, {
          headers: {
            Authorization: `Bearer ${data.grid_nav_token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.data && response.data.status == "success") {
            let res = response.data.response;

            window.Event.$emit("gridcodeAddedEvent", res);

            commit("setIsSuccess", true, {
              root: true,
            });
          }
        });
    },

    sendUpdateProfileRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });

      let endpoint = "";
      if (data.role == "Driver") {
        endpoint = "/api/provider/profile";
      } else {
        endpoint = "/api/user/update/profile";
      }

      return axios
        .post(endpoint, data.data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response && response.status == 200) {
            commit("setIsSuccess", true, {
              root: true,
            });
            let res = response.data;
            if (!res.picture) {
              res.picture = res.avatar;
            }

            if (res) {
              window.Event.$emit("userDataEvent", res);
            }
          }
          commit("setIsLoading", false, {
            root: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendVerifyCodeRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      return axios.post("email/verify", data).then((response) => {
        localStorage.removeItem("isEmailVerified");
        if (response && response.data) {
          let res = response.data;

          commit("setUserData", res.data);

          if (res.data.isEmailVerified) {
            localStorage.setItem("isEmailVerified", true);
          }

          commit("setIsSuccess", true, {
            root: true,
          });
        }
      });
    },

    sendRegisterRequest({ commit }, data) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      let endpoint =
        data.role == "Driver" ? "/api/provider/register" : "/api/user/signup";

      return axios.post(endpoint, data.data).then((response) => {
        if (response && response.data) {
          let res = response.data;
          commit("setUserData", res.data);
          localStorage.removeItem("isEmailVerified");
          localStorage.removeItem("authToken");
          // if (res.data.isEmailVerified) {
          //   localStorage.setItem("isEmailVerified", true);
          // }
          commit("setIsSuccess", true, {
            root: true,
          });
        }
      });
    },
    sendLogoutRequest({ commit }) {
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      return axios.post(process.env.VUE_APP_API_URL + "logout").then(() => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("isEmailVerified");
      });
    },
    sendVerifyResendRequest({ commit }) {
      commit("setIsResending", true, {
        root: true,
      });
      commit(
        "setErrors",
        {},
        {
          root: true,
        }
      );
      commit("setIsLoading", true, {
        root: true,
      });
      commit("setIsSuccess", false, {
        root: true,
      });
      localStorage.removeItem("isEmailVerified");
      return axios.get(VUE_APP_API_URL + "email/resend");
    },
    sendVerifyRequest({ dispatch }, hash) {
      return axios.get(VUE_APP_API_URL + "email/verify/" + hash).then(() => {
        dispatch("getUserData");
      });
    },
  },
};
